
import './App.css';
import { Routes, Route} from "react-router-dom";
import Landing from "./Landing.js";
import Home from "./Home.js";


function App() {
  return (
    <div className="App">

    <Routes>
    <Route exact path="/" element={<Landing />} />
    <Route exact path="/home" element={<Home />} />

    </Routes>
      </div>
  );
}

export default App;
