import logo from "./still_moments_logo.png";
import facebook from "./facebook.png";
import snapchat from "./snapchat.png";
import tiktok from "./tiktok.png";
import instagram from "./instagram.png";
import booth from "./booth.jpeg";
import booth1 from "./booth1.jpg";
import booth2 from "./booth2.jpg";
import booth3 from "./booth3.jpg";
import { Link } from "react-router-dom";
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import Item from "./Item"

import "./Landing.css";

function Landing(props) {

  let items = [
    {
        name: "Random Name #1",
        description: "Probably the most random thing you have ever seen!"
    },
    {
        name: "Random Name #2",
        description: "Hello World!"
    }
]


  return (
    <div className="Landing">

      <div className="top_section">
      <div className="bio">
        <img src={logo} className="nav_logo" alt="logo" />
        <p>
          Welcome to Still Moments, the premier destination for all your
          photobooth rental needs. We pride ourselves on providing exceptional
          service, top-of-the-line equipment, and unforgettable experiences for
          any occasion. At Still Moments, we understand the power of capturing
          moments. Whether it's a wedding, corporate event, birthday
          celebration, or any other special gathering, our photobooth rentals
          add a touch of excitement and create lasting memories for you and your
          guests. 
          </p>

          <p>Choose Still Moments for your next event and let us transform ordinary
          moments into extraordinary memories. Contact us today to discuss your
          requirements and secure the perfect photobooth rental for your special
          occasion. We look forward to being a part of your unforgettable
          moments!
        </p>
      </div>
      <div className="link_box">

      <Link className="book_button" to={"home"}>
          <h2>Get a quote</h2>
        </Link>
        <a
          className="social_button"
          href="https://www.instagram.com/still_moments_photobooth"
          target="blank"
        >
          <div className="social_media">
            <img src={instagram} alt="social_media" />
          </div>
          <h2> Instagram </h2>
        </a>

        <a
          className="social_button"
          href="https://www.facebook.com/profile.php?id=100090346599352"
          target="blank"
        >
          <div className="social_media">
            <img src={facebook} alt="social_media" />
          </div>
          <h2> Facebook </h2>
        </a>

        <a
          className="social_button"
          href="https://www.tiktok.com/@stillmomentsphotobooth"
          target="blank"
        >
          <div className="social_media">
            <img src={tiktok} alt="social_media" />
          </div>
          <h2> TikTok </h2>
        </a>

      </div>
      </div>

      <div className="details">

      <div className="detail_box">
        {/* <img className="detail_pic" src={booth} /> */}
        <h3 className="detail_p">
          Silver Package (2 hours)
        </h3>

        <h4 className="package_details">
          Choice of Backdrop
        </h4>
        <h4 className="package_details">
          Timely Delivery
        </h4>
        <h4 className="package_details">
          Set Up and Take Down
        </h4>
        <h4 className="package_details">
          Unlimited Digital Sessions
        </h4>
        <h4 className="package_details">
          Friendly Attendant
        </h4>
        <h4 className="package_details">
          Custom Template
        </h4>
        <h4 className="package_details">
          30+ Props
        </h4>
        <h4 className="package_details">
          Instant 2x6 Prints
        </h4>
        <h4 className="package_details">
          Link to all Photos sent to Host after Event
        </h4>
        
      </div>
      
      <div className="detail_box">
        {/* <img className="detail_pic" src={booth} /> */}
        <h3 className="detail_p">
        Gold Package (3 hours)
        </h3>

        <h4 className="package_details">
          Choice of Backdrop
        </h4>
        <h4 className="package_details">
          Timely Delivery
        </h4>
        <h4 className="package_details">
          Set Up and Take Down
        </h4>
        <h4 className="package_details">
          Unlimited Digital Sessions
        </h4>
        <h4 className="package_details">
          Friendly Attendant
        </h4>
        <h4 className="package_details">
          Custom Template
        </h4>
        <h4 className="package_details">
          30+ Props
        </h4>
        <h4 className="package_details">
          Instant 2x6 Prints
        </h4>
        <h4 className="package_details">
          Link to all Photos sent to Host after Event
        </h4>
      </div>
     

      <div className="detail_box">
        {/* <img className="detail_pic" src={booth} /> */}
        <h3 className="detail_p">
         Platinum Package (4 hours)
        </h3>

        <h4 className="package_details">
          Choice of Backdrop or Enclosure
        </h4>
        <h4 className="package_details">
          Timely Delivery
        </h4>
        <h4 className="package_details">
          Set Up and Take Down
        </h4>
        <h4 className="package_details">
          Unlimited Digital Sessions
        </h4>
        <h4 className="package_details">
          Friendly Attendant
        </h4>
        <h4 className="package_details">
          Custom Template
        </h4>
        <h4 className="package_details">
          30+ Props
        </h4>
        <h4 className="package_details">
          Instant 2x6 Prints
        </h4>
        <h4 className="package_details">
          Link to all Photos sent to Host after Event
        </h4>

      </div>

      </div>

      {/* <Carousel >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel> */}
    </div>
  );
}

export default Landing;
