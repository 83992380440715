import { useState } from 'react';
import React from 'react'
import './Home.css';
import { Link } from "react-router-dom";
import AutoComplete from 'react-google-autocomplete'

import booth from './booth.jpeg';
import booth1 from './booth1.jpg';
import booth2 from './booth2.jpg';
import booth3 from './booth3.jpg';
import space from './space-27.png';
import urban from './urban-family-portrait.png';
import logo from './still_moments_logo.png';


export default function Home() {
  const [name, setname] = useState("")
  const [email, setemail] = useState("")
  const [subject, setsubject] = useState("BOOKING")
  const [date, setdate] = useState("")
  const [hours, sethours] = useState("4")
  const YOUR_GOOGLE_MAPS_API_KEY = 'AIzaSyCbg9bZecqhRYkoBonU73ObrULuFFr3bxk'
  

  return (
    <div className='Home'>

      <div className='book'>

      <Link className="logo_box"  to={'/'}>
      <img className='logo_img' src={logo}  />
      </Link>
      
     
      <img className='gallery' src={urban}  />
      </div>

      <form className='booking_form' action="https://formspree.io/f/mgebbqpk" method="POST">
        <h1>Get a quote</h1>
        <p className='booking_text'> Once your request is sent you will be contacted by a representative to confirm your rental.</p>
        
        <label for='first-name'> First Name </label>
        <input name='first-name' type='text' required></input>

        <label for='last-name'> Last Name </label>
        <input name='last-name' type='text' required></input>

        
        <label for='email' type="email" required> Email </label>
        <input name='email'></input>
        
        <label for='phonenumber' type="phonenumber" required> Phone number </label>
        <input name='phonenumber'></input>

        {/* <label for='address' > Delivery Address </label>
        <AutoComplete
          name='address'
            apiKey={YOUR_GOOGLE_MAPS_API_KEY}
            style={{ width: "100%" }}
            onPlaceSelected={(place) => {
              console.log(place);
            }}
            options={{
              types: ["address"],
              componentRestrictions: { country: "us" },
            }}
            
          /> */}
       
       
        {/* <label for='date' > Date </label>
        <input name='date' type='date' required></input> */}

<label for='notes' > Additonal Notes </label>
        <textarea name='notes' type='text' required></textarea>



{/*         
         <label for='hours'> Hours </label>
        <select name="hours" id="hours" required>
    <option value="2 ($399)">2 hours ($399)</option>
    <option value="3 ($499)">3 hours ($499)</option>
    <option value="4($599)">4 hours ($599)</option>
    <option value="5 ($550)">5 hours ($550)</option>
    </select>

    <div>
    <p>
    $125 an hour to extend a booking 
    <p></p>
    $50 discount for like and follow / google review 
    </p> 

    </div> */}

        <button type='submit'>submit</button>
      </form>
      </div>
  )
}
